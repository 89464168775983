const requireModule = require.context('.', false,  /\.onboard\.js$/);
const tours = {};

requireModule.keys().forEach(filename => {

    const moduleName = "ONBOARD_" +  filename
        .replace(/(\.\/|\.onboard\.js)/g, '').toUpperCase()

    tours[moduleName] =  requireModule(filename).default || requireModule(filename);
});

export default tours;