<template>
  <div>
    <div class="d-flex justify-space-between">
      <h4 v-if="title" class="font-weight-medium">{{ title }}</h4>
      <v-chip v-if="action_text" outlined :color="wsACCENT" class="px-4">
        <h3> {{ action_text }} </h3>
      </v-chip>
    </div>

    <apexchart type="line" :height="height"   :options="chartOptions" :series="chartSeries" />
  </div>
</template>

<script>
import apexchart from "vue-apexcharts";

export default {
  name: "wsChart",
  components : {
    apexchart
  },
  props : {
    title : {
      type : String,
      default : 'sd'
    },
    action_text : {
      type : String,
      default : ''
    },
    height : {
      type : String,
      default : '250'
    },
    labels : {
      type : Array,
      default() { return [] }
    },
    series : {
      type : Array,
      default() { return [] }
    },
    seriesName : {
      type : String,
      default : ''
    }
  },
  data() {
    return {
      seriesData : []
    }
  },
  computed : {
    chartSeries() {
      return [{
        name: this.seriesName,
        data: this.series
      }]
    },
    chartOptions() {
      return {
        chart: {
          height : 250,
          type: 'line',
          fontFamily : 'Montserrat,Helvetica,Arial,sans-serif',
          selection: {
            enabled: false,
          },
          zoom: {
            enabled: false
          },
          floating : true,
          toolbar : {
            tools: {
              download: false
            }
          },
        },
        colors : [this.wsACCENT],
        dataLabels: {
          enabled: true
        },

        stroke: {
          curve: 'smooth',
          width : 3,
        },

        grid: {
          show : false,
        },
        yaxis : {
          show: false,
          floating : true,
          axisBorder : { show : false },
          axisTicks : { show : false },
          min : 0,
        },
        xaxis: {
          // axisBorder : { show : false },
          // axisTicks : { show : false },
          // floating : true,
          categories: this.labels,
        }
      }
    },
  }
}
</script>

<style scoped>

</style>