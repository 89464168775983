export default {

    init($this) {

        if ($this.$store.state.business.selectedBusiness.chat_bots_main_tour_finished) {
            return
        }

        $this.$store.dispatch('onboarding/DISPLAY_ONBOARD_DIALOG','ChatBots_Dashboard')
    }

}